import cn from 'classnames';
import React from 'react';
import styles from './search-button.module.scss';
import { SearchIcon } from '@y2/mango/components/icons';

type Props = {
  isCollapsed: boolean;
  isValid: boolean;
};

export const SearchButton = ({ isCollapsed, isValid }: Props) => {
  return (
    <button
      className={cn(styles.searchButton, !isCollapsed && 'desktop-only')}
      disabled={!isValid}
      type="submit"
    >
      <SearchIcon />
    </button>
  );
};
