import { AutocompleteGroup } from '../../components/autocomplete/autocomplete-group/autocomplete-group';
import { AutocompleteItem } from '../../components/autocomplete/autocomplete-item/autocomplete-item';
import { AutocompleteInput } from '../../components/autocomplete/autocomplete-input/autocomplete-input';
import styles from './cover-address-autocomplete.module.scss';
import texts from './cover-address-autocomplete.texts.json';
import React, {
  useEffect,
  useId,
  useState,
  type Dispatch,
  type SetStateAction,
} from 'react';
import cn from 'classnames';
import { useCollapse } from '@y2/hooks/use-collapse';
import { useCombobox, UseComboboxStateChange } from 'downshift';
import { useDisableBodyScrollOnMobileWithKeyboard } from '@y2/hooks/use-disable-mobile-scroll';
import { MobileDrawerHeader } from '@y2/feed-search/components/ui/mobile-drawer-header';
import { AutoCompleteEntities } from '../../query-hooks/use-address-autocomplete/autocomplete-fetcher';
import { useAddressAutocomplete } from '../../query-hooks/use-address-autocomplete/use-address-autocomplete';
import { AutocompleteDropdownList } from '../../components/autocomplete/autocomplete-dropdown-list/autocomplete-dropdown-list';
import { AddressMasterResponse } from '../../query-hooks/address/use-address-master';
import { SearchButton } from '../../components/autocomplete/search-button/search-button';

export type AddressMaster = AutoCompleteEntities;

type Props = {
  className?: string;
  mobileHeading: string;
  onChange: (value: AddressMaster | null | undefined) => void;
  setIsValid: Dispatch<SetStateAction<boolean>>;
  isValid: boolean;
  initialAddress?: AddressMasterResponse | null;
};

export const CoverAddressAutocomplete = ({
  className,
  onChange,
  mobileHeading,
  setIsValid,
  isValid,
  initialAddress,
}: Props) => {
  const { isCollapsed, collapse, expand, collapseRef } = useCollapse();
  const comboboxId = useId();
  const [items, setItems] = useState<AddressMaster[]>([]);

  const onSelectedItemChange = (
    value: UseComboboxStateChange<AddressMaster | null>,
  ) => {
    onChange(value.selectedItem);
    collapse();
  };

  const itemToString = (item: AddressMaster | null) =>
    item?.full_title_text || '';

  const {
    isOpen,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    getInputProps,
    inputValue,
    getComboboxProps,
    closeMenu,
    selectedItem,
    selectItem,
  } = useCombobox<AddressMaster | null>({
    onSelectedItemChange,
    initialSelectedItem: initialAddress,
    items,
    itemToString,
    id: comboboxId,
  });

  useDisableBodyScrollOnMobileWithKeyboard(!isCollapsed);

  // Similar to onInputValueChange but works better with the selectedItem
  useEffect(() => {
    if (selectedItem && !inputValue) {
      selectItem(null);
      onChange(null);
    }
    setIsValid(
      Boolean(!inputValue || inputValue.trim() === itemToString(selectedItem)),
    );
  }, [selectedItem, inputValue]);

  const { data } = useAddressAutocomplete(inputValue);

  useEffect(() => {
    if (data) {
      setItems([
        ...(data.hoods || []),
        ...(data.cities || []),
        ...(data.areas || []),
      ]);
    }
  }, [data]);

  useEffect(() => {
    if (isCollapsed) {
      closeMenu();
    }
  }, [isCollapsed]);

  const closeAndCollapse = () => {
    collapse();
    closeMenu();
  };

  return (
    <div
      className={cn(
        styles.autocompleteBox,
        !isCollapsed && styles.isOpen,
        className,
      )}
      {...getComboboxProps({ id: comboboxId, ref: collapseRef })}
    >
      {!isCollapsed && (
        <MobileDrawerHeader onCloseClick={closeAndCollapse}>
          {mobileHeading}
        </MobileDrawerHeader>
      )}
      <AutocompleteInput
        onClick={expand}
        isCollapsed={isCollapsed}
        getInputProps={getInputProps}
        placeholder={texts.placeholder}
      >
        <SearchButton isCollapsed={isCollapsed} isValid={isValid} />
      </AutocompleteInput>
      <AutocompleteDropdownList isOpen={isOpen || (!isValid && !isCollapsed)}>
        <AutocompleteGroup
          getMenuProps={getMenuProps}
          groupHeading={texts.hood}
          hidden={(data?.hoods?.length || 0) === 0}
        >
          {data?.hoods.map((item, index) => (
            <AutocompleteItem<AutoCompleteEntities>
              key={index}
              highlightedIndex={highlightedIndex}
              searchTerm={inputValue.trim()}
              getItemProps={getItemProps}
              item={item}
              index={index}
              text={item.full_title_text}
            />
          ))}
        </AutocompleteGroup>
        <AutocompleteGroup
          getMenuProps={getMenuProps}
          groupHeading={texts.city}
          hidden={(data?.cities?.length || 0) === 0}
        >
          {data?.cities.map((item, index) => {
            const offsetIndex = (data.hoods?.length || 0) + index;
            return (
              <AutocompleteItem<AutoCompleteEntities>
                key={offsetIndex}
                highlightedIndex={highlightedIndex}
                searchTerm={inputValue.trim()}
                getItemProps={getItemProps}
                item={item}
                index={offsetIndex}
                text={item.full_title_text}
              />
            );
          })}
        </AutocompleteGroup>
        <AutocompleteGroup
          getMenuProps={getMenuProps}
          groupHeading={texts.area}
          hidden={(data?.areas?.length || 0) === 0}
        >
          {data?.areas.map((item, index) => {
            const offsetIndex =
              (data.hoods?.length || 0) + (data.cities?.length || 0) + index;
            return (
              <AutocompleteItem<AutoCompleteEntities>
                key={offsetIndex}
                highlightedIndex={highlightedIndex}
                getItemProps={getItemProps}
                searchTerm={inputValue.trim()}
                item={item}
                index={offsetIndex}
                text={item.full_title_text}
              />
            );
          })}
        </AutocompleteGroup>
      </AutocompleteDropdownList>
    </div>
  );
};
