/* istanbul ignore file */
import {
  addressMasterV1Client,
  AreaData,
  CityData,
  HoodData,
} from '@y2/api-clients/address-master-v1';

const RESULTS_LIMIT = 4;
const MIN_SEARCH_PHRASE_LENGTH = 2;

const client = addressMasterV1Client();

const defaultData = {
  hoods: [],
  cities: [],
  areas: [],
};

export type AutoCompleteEntities = AreaData | CityData | HoodData;

export const autocompleteFetcher = async (searchPhrase: string) => {
  if (searchPhrase.length < MIN_SEARCH_PHRASE_LENGTH) return defaultData;

  const {
    data: { data },
  } = await client.getAutocomplete<AutoCompleteEntities>(searchPhrase, {
    limit: RESULTS_LIMIT,
    entities: 'hoods,cities,areas',
  });
  return data;
};
