import React from 'react';
import texts from './page-about-text.texts.json';
import styles from './page-about-text.module.scss';
import { Container } from '../../components/container/container';

type Props = {
  aboutText: string;
};

export const PageAboutText = ({ aboutText }: Props) => (
  <Container className={styles.aboutText}>
    <h2 className={styles.heading}>{texts.heading}</h2>
    <p className={styles.text}>{aboutText}</p>
  </Container>
);
