import cn from 'classnames';
import React, { useEffect, useRef } from 'react';
import styles from './autocomplete-item.module.scss';
import { UseComboboxGetItemPropsOptions } from 'downshift';
import { HighlightedText } from '../../highlighted-text/highlighted-text';

type Props<Item> = {
  className?: string;
  highlightedIndex: number;
  searchTerm: string;
  getItemProps: (options: UseComboboxGetItemPropsOptions<Item>) => any;
  index: number;
  item: Item;
  text: string;
};

export const AutocompleteItem = <Item = Record<string, unknown>,>({
  className,
  highlightedIndex,
  getItemProps,
  searchTerm,
  index,
  item,
  text,
}: Props<Item>) => {
  const itemRef = useRef<HTMLLIElement | null>(null);
  const isHighlighted = highlightedIndex === index;

  useEffect(() => {
    if (isHighlighted && itemRef.current) {
      itemRef.current.scrollIntoView({
        block: 'nearest',
        behavior: 'smooth',
      });
    }
  }, [isHighlighted]);

  return (
    <li
      {...getItemProps({ index, item, ref: itemRef })}
      className={cn(
        styles.autocompleteItem,
        isHighlighted && styles.highlighted,
        className,
      )}
    >
      <HighlightedText text={text} searchTerm={searchTerm} />
    </li>
  );
};
