import styles from './cover.module.scss';
import cn from 'classnames';
import React from 'react';

type Props = {
  className?: string;
  children?: React.ReactNode;
  bgVideo?: string;
  bgImage?: string;
  pageHeading: string;
};

export const Cover = ({
  className,
  children,
  bgVideo,
  bgImage,
  pageHeading,
}: Props) => {
  return (
    <div className={cn(styles.coverBox, className)}>
      <div
        className={styles.bgBox}
        style={{ ...(bgImage && { backgroundImage: `url(${bgImage})` }) }}
      >
        {bgVideo && (
          <video
            autoPlay
            muted
            loop
            playsInline
            className={styles.bgVideo}
            src={bgVideo}
          />
        )}
      </div>
      <h1 className={styles.pageHeading}>{pageHeading}</h1>
      {children}
    </div>
  );
};
