import React from 'react';
import Head from 'next/head';

type Props = {
  metaTitle: string;
  metaDescription: string;
  canonicalUrl: string;
  robotContent: string;
  children?: React.ReactNode;
};

export const MetaTags = ({
  metaTitle,
  metaDescription,
  canonicalUrl,
  robotContent,
  children,
}: Props) => (
  <Head>
    <title key="title">{metaTitle}</title>
    <meta name="description" content={metaDescription} />
    <meta name="og:title" content={metaTitle} />
    <meta name="og:description" content={metaDescription} />
    <meta name="robots" content={robotContent} />
    <link rel="canonical" href={canonicalUrl} />
    {children}
  </Head>
);
