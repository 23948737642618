import {
  GetPropsCommonOptions,
  UseComboboxGetInputPropsOptions,
} from 'downshift';
import styles from './autocomplete-input.module.scss';
import cn from 'classnames';
import React, { useId } from 'react';

type Props = {
  className?: string;
  children?: React.ReactNode;
  isCollapsed: boolean;
  getInputProps: (
    options?: UseComboboxGetInputPropsOptions,
    otherOptions?: GetPropsCommonOptions,
  ) => any;
  onClick: React.MouseEventHandler<HTMLInputElement>;
  placeholder: string;
};

export const AutocompleteInput = ({
  className,
  children,
  getInputProps,
  isCollapsed,
  onClick,
  placeholder,
}: Props) => {
  const inputId = useId();

  return (
    <div
      className={cn(
        styles.autocompleteInputBox,
        !isCollapsed && styles.isOpen,
        className,
      )}
    >
      <input
        type="text"
        onClick={onClick}
        placeholder={placeholder}
        {...getInputProps({ id: inputId })}
      />
      {children}
    </div>
  );
};
