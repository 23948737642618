import styles from './breadcrumb-item.module.scss';
import Link from 'next/link';
import React from 'react';

type Props = {
  title: string;
  href: string;
  isLastBreadcrumb: boolean;
  position: string;
};

export const BreadcrumbItem = ({
  title,
  href,
  isLastBreadcrumb,
  position,
}: Props) => {
  return (
    <li
      className={styles.breadcrumbItemBox}
      itemProp="itemListElement"
      itemScope
      itemType="https://schema.org/ListItem"
      data-nagish="breadcrumbs-breadcrumb-item"
    >
      {!isLastBreadcrumb ? (
        <Link
          itemProp="item"
          href={href}
          data-testid="breadcrumb-item-link"
          data-nagish="breadcrumb-item-link"
        >
          <span itemProp="name">{title}</span>
        </Link>
      ) : (
        <span itemProp="name">{title}</span>
      )}

      <meta itemProp="position" content={position} />
    </li>
  );
};
