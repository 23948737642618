import { Container } from '../container/container';
import styles from './cover-form.module.scss';
import cn from 'classnames';
import React from 'react';

type Props = {
  className?: string;
  children?: React.ReactNode;
} & React.DetailedHTMLProps<
  React.FormHTMLAttributes<HTMLFormElement>,
  HTMLFormElement
>;

export const CoverForm = ({ className, children, ...formAttr }: Props) => {
  return (
    <Container className={cn(styles.coverFormContainer, className)}>
      <form {...formAttr}>{children}</form>
    </Container>
  );
};
