import styles from './autocomplete-dropdown-list.module.scss';
import cn from 'classnames';
import React from 'react';

type Props = {
  className?: string;
  children?: React.ReactNode;
  isOpen: boolean;
};

export const AutocompleteDropdownList = ({
  className,
  children,
  isOpen,
}: Props) => {
  return (
    <div className={cn(className, styles.dropdownBox)}>
      <ul className={styles.groupList} hidden={!isOpen}>
        {children}
      </ul>
    </div>
  );
};
