import { BreadcrumbItem } from './breadcrumb-item/breadcrumb-item';
import styles from './breadcrumbs.module.scss';
import React, { ComponentProps } from 'react';

export type CrumbItem = Pick<
  ComponentProps<typeof BreadcrumbItem>,
  'title' | 'href'
>;

type Props = {
  breadcrumbs: CrumbItem[];
};

export const Breadcrumbs = ({ breadcrumbs }: Props) => (
  <div className={styles.breadcrumbsBox}>
    <ol
      itemScope
      itemType="https://schema.org/BreadcrumbList"
      className={styles.breadcrumbsList}
    >
      {breadcrumbs.map(({ href, title }, index) => (
        <BreadcrumbItem
          href={href}
          title={title}
          key={index}
          isLastBreadcrumb={index === breadcrumbs.length - 1}
          position={String(index + 1)}
        />
      ))}
    </ol>
  </div>
);
